import axios from "axios";
let ORIGIN_ADDRESS = "https://api.osle.co.kr";
if (
  location.hostname == "www.osle.co.kr" ||
  location.hostname == "osle.co.kr"
) {
  ORIGIN_ADDRESS = "https://api.osle.co.kr";
} else if (
  location.hostname == "www.dev.osle.co.kr" ||
  location.hostname == "dev.osle.co.kr"
) {
  ORIGIN_ADDRESS = "https://dev.api.osle.co.kr";
  // ORIGIN_ADDRESS = "https://api.osle.co.kr";
} else {
  ORIGIN_ADDRESS = "http://localhost:8088";
  // ORIGIN_ADDRESS = "http://211.192.49.163:8088";
  // ORIGIN_ADDRESS = "https://dev.api.osle.co.kr";
  // ORIGIN_ADDRESS = "https://api.osle.co.kr";
}

const API_ADDRESS = ORIGIN_ADDRESS + "/v1/api";

// const API_ADDRESS = '/v1/api'

axios.defaults.headers.common = {
  "Content-Type": "application/json",
  // 'Content-Type': 'application/x-www-form-urlencoded',
  // "Access-Control-Allow-Credentials": true,
  // "Access-Control-Allow-Origin": "http://localhost:8088",
  // 'Cache-Control': 'no-cache'
};

export default {
  comm: {
    pobillUser: (factoryNo) => {
      const fd = new FormData();
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/user/popbill/user`, fd);
    },
    pobillAddUrl: (factoryNo) => {
      const fd = new FormData();
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/user/popbill/add/url`, fd);
    },
    pobillAdd: (factoryNo) => {
      const fd = new FormData();
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/user/popbill/add`, fd);
    },
    download: (fileUrl) => {
      const fd = new FormData();
      console.log(fileUrl);
      fd.append("fileUrl", fileUrl);
      console.log(fd);
      return axios.post(`${API_ADDRESS}/comm/download`, fd, {
        responseType: "blob",
      });
    },
    commData: (userId, compType) => {
      const fd = new FormData();
      console.log(userId);
      fd.append("userId", userId);
      fd.append("compType", compType);
      console.log(fd);
      return axios.post(`${API_ADDRESS}/comm/getData`, fd);
    },
    fileDataUpload: (datas, userId) => {
      // 파일업로드
      const fd = new FormData();
      fd.append("userId", userId);
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/comm/file/data/upload`, fd);
    },
    fileDataUploadMultiple: (id, typeNo, fileStatus, fileType, fileNoList) => {
      // 파일업로드
      const fd = new FormData();
      const headers = { "Content-Type": "form-data" };
      fd.append("userId", id);
      fd.append("typeNo", typeNo);
      fd.append("fileStatus", fileStatus);
      fd.append("fileType", fileType);
      fd.append("fileNoList", fileNoList);
      return axios.post(
        `${API_ADDRESS}/comm/file/data/multi/upload`,
        fd,
        headers
      );
    },
    fileUpload: (id, img) => {
      // 파일업로드
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("img", img);
      return axios.post(`${API_ADDRESS}/comm/file/upload`, fd);
    },
    fileUploadMultiple: (id, typeNo, fileStatus, fileType, img) => {
      // 파일업로드
      const fd = new FormData();
      const headers = { "Content-Type": "form-data" };
      fd.append("userId", id);
      fd.append("typeNo", typeNo);
      fd.append("fileStatus", fileStatus);
      fd.append("fileType", fileType);
      // fd.append('img', img)
      for (let i = 0; i < img.length; i++) {
        fd.append("imgFile", img[i]);
      }
      return axios.post(`${API_ADDRESS}/comm/file/multi/upload`, fd, headers);
    },
    fileUploadMultipleWatermark: (id, typeNo, fileStatus, fileType, img) => {
      // 파일업로드
      const fd = new FormData();
      const headers = { "Content-Type": "form-data" };
      fd.append("userId", id);
      fd.append("typeNo", typeNo);
      fd.append("fileStatus", fileStatus);
      fd.append("fileType", fileType);
      // fd.append('img', img)
      for (let i = 0; i < img.length; i++) {
        fd.append("imgFile", img[i]);
      }
      return axios.post(
        `${API_ADDRESS}/comm/file/multi/upload/watermark`,
        fd,
        headers
      );
    },
    fileUploadWatermark: (id, img) => {
      // 파일업로드
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("img", img);
      // return axios.post(`${API_ADDRESS}/comm/file/upload`, fd)
      return axios.post(`${API_ADDRESS}/comm/file/upload/watermark`, fd);
    },
    osleIntro: () => {
      //오슬 소개 페이지
      return axios.post(`${API_ADDRESS}/comm/osleIntro`);
    },
    realtimeUse: () => {
      return axios.post(`${API_ADDRESS}/comm/matchingAndMate`);
    },
    visit: () => {
      console.log(document.referrer);
      const fd = new FormData();
      const referer = document.referrer;
      const visitUrl = location.href;
      let userId =
        new URLSearchParams(window.location.search).get("userId") ||
        sessionStorage.getItem("userId");
      if (userId) fd.append("userId", userId);
      if (referer) fd.append("referer", referer);
      if (visitUrl) fd.append("visitUrl", visitUrl);
      if (sessionStorage.getItem("compType"))
        fd.append("compType", sessionStorage.getItem("compType"));

      return axios.post(`${API_ADDRESS}/user/visit`, fd);
    },
    bannerEvent: (bannerUrl, bannerName) => {
      const fd = new FormData();
      fd.append("bannerUrl", bannerUrl);
      fd.append("bannerName", bannerName);
      if (sessionStorage.getItem("userId"))
        fd.append("userId", sessionStorage.getItem("userId"));
      if (sessionStorage.getItem("compType"))
        fd.append("compType", sessionStorage.getItem("compType"));
      return axios.post(`${API_ADDRESS}/user/banner/move`, fd);
    },
    btnEvent: (buttonType, resultCode) => {
      const fd = new FormData();
      fd.append("buttonType", buttonType);
      if (sessionStorage.getItem("userId"))
        fd.append("userId", sessionStorage.getItem("userId"));
      if (sessionStorage.getItem("compType"))
        fd.append("compType", sessionStorage.getItem("compType"));
      fd.append("resultCode", resultCode ? resultCode : "SU");
      return axios.post(`${API_ADDRESS}/user/active/button`, fd);
    },
    btnFacEvent: (factoryNo, buttonType) => {
      const fd = new FormData();
      if (sessionStorage.getItem("userId"))
        fd.append("userId", sessionStorage.getItem("userId"));
      fd.append("factoryNo", factoryNo);
      fd.append("buttonType", buttonType);
      return axios.post(`${API_ADDRESS}/user/active/factory`, fd);
    },
    checkDi: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/user/di/check`, fd);
    },
    tokenSave: (userId, deviceToken) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("token", deviceToken);
      return axios.post(`${API_ADDRESS}/comm/token/save`, fd);
    },
    veriCheck: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/comm/veri/check`, fd);
    },
    bizCert: (userId, data) => {
      const fd = new FormData();
      fd.append("userId", userId);
      console.log(data);
      data.forEach((e) => {
        fd.append("fileNo", e.compFileNo);
      });
      console.log(fd);
      return axios.post(`${API_ADDRESS}/comm/biz/cert`, fd);
    },
  },
  dashboard: {
    getDesignerMatchingData: (id) => {
      const fd = new FormData();
      fd.append("userId", id);
      return axios.post(`${API_ADDRESS}/user/main/dashboard/matching`, fd);
    },
    getDesignerMateData: (id) => {
      const fd = new FormData();
      fd.append("userId", id);
      return axios.post(`${API_ADDRESS}/user/main/dashboard/mate`, fd);
    },
    getDesignOrderData: (id) => {
      const fd = new FormData();
      fd.append("userId", id);
      return axios.post(`${API_ADDRESS}/user/main/dashboard/designorder`, fd);
    },
    getContractData: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/user/main/dashboard/contract`, fd);
    },
  },
  custom: {
    customRegist: (userId, styleName, customType) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("styleName", styleName);
      fd.append("customType", customType);
      return axios.post(`${API_ADDRESS}/custom/regist`, fd);
    },
    customModify: (datas) => {
      const fd = new FormData();
      console.log(datas);
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/custom/modify`, fd);
    },
    customModifyStyle: (datas) => {
      const fd = new FormData();
      console.log(datas);
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/custom/modify/style`, fd);
    },
    customRemoveStyle: (datas) => {
      const fd = new FormData();
      console.log(datas);
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/custom/remove/style`, fd);
    },
    customModifyThumb: (datas) => {
      const fd = new FormData();
      console.log(datas);
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/custom/modify/thumb`, fd);
    },
    customRegistFin: (datas) => {
      const fd = new FormData();
      console.log(datas);
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/custom/regist/final`, fd);
    },
    customDetail: (userId, customNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("customNo", customNo);
      return axios.post(`${API_ADDRESS}/custom/detail`, fd);
    },
    customList: (userId, datas) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/custom/list`, fd);
    },
    ongoingList: (userId, datas) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/custom/ongoing/list`, fd);
    },
    customOrderList: (userId, customNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("customNo", customNo);
      return axios.post(`${API_ADDRESS}/custom/order/list`, fd);
    },
    customOrder: (userId, customNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("customNo", customNo);
      return axios.post(`${API_ADDRESS}/custom/order`, fd);
    },
    customOrderInfo: (orderNo) => {
      const fd = new FormData();
      fd.append("orderNo", orderNo);
      return axios.post(`${API_ADDRESS}/custom/order/info`, fd);
    },
    customOrderProc: (userInfo, datas) => {
      const fd = new FormData();
      if (userInfo) {
        for (const [key, value] of Object.entries(userInfo)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/custom/order/proc`, fd);
    },
    customOrderProcPayple: (orderId) => {
      const fd = new FormData();
      fd.append("orderId", orderId);
      return axios.post(`${API_ADDRESS}/custom/order/proc/payple`, fd);
    },
    printAdd: (datas) => {
      const fd = new FormData();
      console.log(datas);
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/custom/print/add`, fd);
    },
    printRemove: (userId, customNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("customNo", customNo);
      return axios.post(`${API_ADDRESS}/custom/print/remove`, fd);
    },
    customOrderSize: (userId, orderNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("orderNo", orderNo);
      return axios.post(`${API_ADDRESS}/custom/order/size`, fd);
    },
    customSizeSepc: (
      gender,
      fit,
      totalLength,
      sleeveLength,
      sleeveStyle,
      neckLine,
      neckDetail,
      sleeveEnd,
      hemEnd,
      customType
    ) => {
      const fd = new FormData();
      fd.append("gender", gender);
      fd.append("fit", fit);
      fd.append("totalLength", totalLength ? totalLength : "");
      fd.append("sleeveLength", sleeveLength ? sleeveLength : "");
      fd.append("sleeveStyle", sleeveStyle ? sleeveStyle : "");
      fd.append("neckLine", neckLine ? neckLine : "");
      fd.append("neckDetail", neckDetail ? neckDetail : "");
      fd.append("sleeveEnd", sleeveEnd ? sleeveEnd : "");
      fd.append("hemEnd", hemEnd ? hemEnd : "");
      fd.append("customType", customType ? customType : "");
      return axios.post(`${API_ADDRESS}/custom/size/spec`, fd);
    },
    customMarking: (gender, fit, totalLength, sleeveLength, inch) => {
      const fd = new FormData();
      fd.append("gender", gender);
      fd.append("fit", fit);
      fd.append("totalLength", totalLength);
      fd.append("sleeveLength", sleeveLength);
      fd.append("inch", inch);
      return axios.post(`${API_ADDRESS}/custom/marking`, fd);
    },
    customFabric: (customType) => {
      const fd = new FormData();
      fd.append("customType", customType);
      return axios.post(`${API_ADDRESS}/custom/fabric`, fd);
    },
  },
  search: {
    catg: () => {},
    keyword: (keyword) => {
      const fd = new FormData();
      fd.append("keyword", keyword);
      return axios.post(`${API_ADDRESS}/search/keyword`, fd);
    },
    factoryList: (
      id,
      datas,
      pageNow,
      pageList,
      premiumFlag,
      sortType,
      sortFlag,
      catg1List,
      catg2List,
      catg3List,
      noFactoryList,
      isSearchLike
    ) => {
      console.log(datas);
      // 공장 목록 불러오기
      const fd = new FormData();
      fd.append("userId", id);
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      if (premiumFlag) fd.append("premiumFlag", premiumFlag ? "1" : "0");
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (sortType) fd.append("sortType", sortType);
      if (sortFlag) fd.append("sortFlag", sortFlag ? "1" : "0");
      if (noFactoryList) fd.append("noFactoryList", noFactoryList);
      if (catg1List) fd.append("catg1List", catg1List);
      if (catg2List) fd.append("catg2List", catg2List);
      if (catg3List) fd.append("catg3List", catg3List);
      if (isSearchLike) fd.append("likeFlag", isSearchLike ? "1" : "0");
      return axios.post(`${API_ADDRESS}/search/factoryList`, fd);
    },
    like: (id, factoryNo, likeType) => {
      // 좋아요
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("factoryNo", factoryNo);
      fd.append("likeType", likeType);
      return axios.post(`${API_ADDRESS}/search/like`, fd);
    },
    factoryDetail: (userId, factoryNo) => {
      // 매칭 내역 상세 불러오기
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/search/detail`, fd);
    },
    factoryDetailV3: (userId, factoryNo) => {
      // 매칭 내역 상세 불러오기
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/search/detail/v3`, fd);
    },
    factoryMap: (datas) => {
      // 공장 목록 불러오기
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      console.log(fd);
      return axios.post(`${API_ADDRESS}/search/factoryMap`, fd);
    },
    factoryFilterList: () => {
      // 공장 목록 불러오기
      return axios.post(`${API_ADDRESS}/search/filterList`);
    },
    factoryFilterMap: (searchFilter) => {
      // 공장 목록 불러오기
      const fd = new FormData();
      fd.append("areaNo", searchFilter.areaNo);
      fd.append("area1", searchFilter.area1);
      fd.append("area2", searchFilter.area2);
      return axios.post(`${API_ADDRESS}/search/map/area`, fd);
    },
  },
  matching: {
    getFactoryInfo: (factoryNo) => {
      const fd = new FormData();
      fd.append("factoryNo", factoryNo);
      return axios.post(
        `${API_ADDRESS}/matching/select/factoryNameSafeTel`,
        fd
      );
    },
    matchingList: (userId, datas, pageNow, pageList) => {
      // 매칭 내역 불러오기
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      return axios.post(`${API_ADDRESS}/matching/list`, fd);
    },
    matchingDetails: (userId, matchingNo, kakaoFlag, factoryNo) => {
      // 매칭 내역 상세 불러오기
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      if (kakaoFlag) fd.append("kakaoFlag", kakaoFlag);
      if (factoryNo) fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/matching/list/detail/factory`, fd);
    },
    // matchingProposal: (userId, matchingNo) => { // 매칭 내역 상세 - 요청서 보기
    //   const fd = new FormData()
    //   fd.append('userId', userId)
    //   fd.append('matchingNo', matchingNo)
    //   return axios.post(`${API_ADDRESS}/matching/list/detail/factory`, fd)
    // },
    matchingRequeinfo: (matchingNo, factoryNo) => {
      // 매칭 내역 요청자 정보 불러오기
      const fd = new FormData();
      fd.append("matchingNo", matchingNo);
      if (factoryNo) fd.append("factoryNo", factoryNo);
      return axios.post(
        `${API_ADDRESS}/matching/list/matchingDesignerInfo`,
        fd
      );
    },
    catg: (depth, catgCd1, catgCd2, factoryNo) => {
      const fd = new FormData();
      fd.append("catgDepth", depth);
      if (catgCd1) fd.append("catgCd1", catgCd1);
      if (catgCd2) fd.append("catgCd2", catgCd2);
      if (factoryNo) fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/matching/catg`, fd);
    },
    getTagList: () => {
      return axios.post(`${API_ADDRESS}/matching/regist/matching/tagList`);
    },
    loadData: (id) => {
      const fd = new FormData();
      fd.append("userId", id);
      return axios.post(
        `${API_ADDRESS}/matching/regist/matching/loadSaveInfo`,
        fd
      );
    },
    saveData: (datas) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(datas)) {
        fd.append(key, value === null ? "" : value);
      }
      return axios.post(`${API_ADDRESS}/matching/modify/matching/proc`, fd);
    },
    complete: (id, matchingNo) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("matchingNo", matchingNo);
      return axios.post(`${API_ADDRESS}/matching/regist/matching/proc/pro`, fd);
    },
    check: (id) => {
      const fd = new FormData();
      fd.append("userId", id);
      return axios.post(
        `${API_ADDRESS}/matching/regist/matching/saveCheck`,
        fd
      );
    },
    newMatching: (id, itemCatgCd, matchingCatg) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("itemCatgCd", itemCatgCd);
      if (matchingCatg) fd.append("matchingCatg", matchingCatg);
      return axios.post(
        `${API_ADDRESS}/matching/regist/matching/newRegist`,
        fd
      );
    },
    deleteAndNewMatching: (id, itemCatgCd, factoryNo, matchingCatg) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("itemCatgCd", itemCatgCd);
      if (factoryNo) {
        fd.append("factoryNo", factoryNo);
      }
      if (matchingCatg) fd.append("matchingCatg", matchingCatg);
      return axios.post(
        `${API_ADDRESS}/matching/regist/matching/saveDeleteAndNew`,
        fd
      );
    },
    copyMatching: (id, matchingNo) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("matchingNo", matchingNo);
      return axios.post(
        `${API_ADDRESS}/matching/regist/matching/styleCopy/proc`,
        fd
      );
    },
    deleteMatching: (id, matchingNo) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("matchingNo", matchingNo);
      return axios.post(
        `${API_ADDRESS}/matching/regist/matching/saveDelete`,
        fd
      );
    },
    // process: (depth, category1, category2) => {
    //   const fd = new FormData()
    //   fd.append('catgDepth', depth)
    //   if (category1) fd.append('catgCd1', category1)
    //   if (category2) fd.append('cateCd2', category2)
    //   return axios.post(`${API_ADDRESS}/matching/regist/matching/selectProcess`, fd)
    // },
    selectFactory: (datas) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(datas)) {
        fd.append(key, value === null ? "" : value);
      }
      return axios.post(
        `${API_ADDRESS}/matching/list/detail/facSelect/final/proc`,
        fd
      );
    },
    requestDesign: (userId, matchingNo, factoryNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/matching/list/requestDesign/proc`, fd);
    },
    requestUnreading: (userId, matchingNo, factoryNo, unreadingFlag) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      fd.append("factoryNo", factoryNo);
      fd.append("unreadingFlag", unreadingFlag);
      return axios.post(
        `${API_ADDRESS}/matching/list/requestDesign/unreading/proc`,
        fd
      );
    },
    requestEstimate: (
      userId,
      matchingNo,
      factoryNo,
      tagList,
      minPrice,
      maxPrice,
      description
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      fd.append("factoryNo", factoryNo);
      fd.append("estimateCheck", tagList);
      fd.append("minUnitCost", minPrice);
      fd.append("maxUnitCost", maxPrice);
      fd.append("styleAmountDesc", description);
      return axios.post(
        `${API_ADDRESS}/matching/factory/detail/estimate/proc`,
        fd
      );
    },
    getEstimate: (userId, matchingNo, factoryNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      fd.append("factoryNo", factoryNo);
      return axios.post(
        `${API_ADDRESS}/matching/list/detail/facList/message`,
        fd
      );
    },
    matchingListFactory: (userId, datas, pageNow, pageList) => {
      // 매칭 내역 불러오기
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      return axios.post(`${API_ADDRESS}/matching/list/factory`, fd);
    },
    matchingStyleCopy: (userId, matchingNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      return axios.post(
        `${API_ADDRESS}/matching/regist/matching/LoadAndSave`,
        fd
      );
    },
    rating: (
      userId,
      matchingNo,
      factoryNo,
      qualityPoint,
      pricePoint,
      communicationPoint,
      dealPlan
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      fd.append("factoryNo", factoryNo);
      fd.append("qualityPoint", qualityPoint);
      fd.append("pricePoint", pricePoint);
      fd.append("communicationPoint", communicationPoint);
      fd.append("dealPlan", dealPlan);
      return axios.post(`${API_ADDRESS}/user/regist/factoryRating`, fd);
    },
    getMatchupList: (matchingNo) => {
      const fd = new FormData();
      fd.append("matchingNo", matchingNo);
      return axios.post(`${API_ADDRESS}/matching/list/detail/matchup`, fd);
    },
  },
  mate: {
    registMate: (
      userId,
      item,
      quantity,
      quantityFlag,
      completeDate,
      completeFlag,
      patternFlag,
      fabricFlag,
      workOrderFlag,
      sampleFlag,
      linkList,
      imgList,
      requestDesc
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("item", item);
      fd.append("quantity", quantity);
      fd.append("quantityFlag", quantityFlag);
      fd.append("completeDate", completeDate);
      fd.append("completeFlag", completeFlag);
      fd.append("patternFlag", patternFlag);
      fd.append("fabricFlag", fabricFlag);
      fd.append("workOrderFlag", workOrderFlag);
      fd.append("sampleFlag", sampleFlag);
      fd.append("linkList", linkList);
      fd.append("imgList", imgList);
      fd.append("requestDesc", requestDesc);
      return axios.post(`${API_ADDRESS}/mate/regist/proc`, fd);
    },
    modifyMate: (
      mateNo,
      userId,
      item,
      quantity,
      quantityFlag,
      completeDate,
      completeFlag,
      patternFlag,
      fabricFlag,
      workOrderFlag,
      sampleFlag,
      linkList,
      imgList,
      requestDesc
    ) => {
      const fd = new FormData();
      fd.append("mateNo", mateNo);
      fd.append("userId", userId);
      fd.append("item", item);
      fd.append("quantity", quantity);
      fd.append("quantityFlag", quantityFlag);
      fd.append("completeDate", completeDate);
      fd.append("completeFlag", completeFlag);
      fd.append("patternFlag", patternFlag);
      fd.append("fabricFlag", fabricFlag);
      fd.append("workOrderFlag", workOrderFlag);
      fd.append("sampleFlag", sampleFlag);
      fd.append("linkList", linkList);
      fd.append("imgList", imgList);
      fd.append("requestDesc", requestDesc);
      return axios.post(`${API_ADDRESS}/mate/modify/proc`, fd);
    },
    getFactoryInfo: (factoryNo) => {
      const fd = new FormData();
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mate/select/factoryNameSafeTel`, fd);
    },
    mateList: (userId, datas, pageNow, pageList) => {
      // 매칭 내역 불러오기
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      return axios.post(`${API_ADDRESS}/mate/list`, fd);
    },
    mateDetails: (userId, mateNo, kakaoFlag, factoryNo) => {
      // 매칭 내역 상세 불러오기
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mateNo", mateNo);
      if (kakaoFlag) fd.append("kakaoFlag", kakaoFlag);
      if (factoryNo) fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mate/list/detail`, fd);
    },
    // mateProposal: (userId, mateNo) => { // 매칭 내역 상세 - 요청서 보기
    //   const fd = new FormData()
    //   fd.append('userId', userId)
    //   fd.append('mateNo', mateNo)
    //   return axios.post(`${API_ADDRESS}/mate/list/detail/factory`, fd)
    // },
    mateRequeinfo: (mateNo, factoryNo) => {
      // 매칭 내역 요청자 정보 불러오기
      const fd = new FormData();
      fd.append("mateNo", mateNo);
      if (factoryNo) fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mate/list/mateDesignerInfo`, fd);
    },
    catg: (depth, catgCd1, catgCd2, factoryNo) => {
      const fd = new FormData();
      fd.append("catgDepth", depth);
      if (catgCd1) fd.append("catgCd1", catgCd1);
      if (catgCd2) fd.append("catgCd2", catgCd2);
      if (factoryNo) fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mate/catg`, fd);
    },
    getTagList: () => {
      return axios.post(`${API_ADDRESS}/mate/regist/mate/tagList`);
    },
    loadData: (id) => {
      const fd = new FormData();
      fd.append("userId", id);
      return axios.post(`${API_ADDRESS}/mate/regist/mate/loadSaveInfo`, fd);
    },
    saveData: (datas) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(datas)) {
        fd.append(key, value === null ? "" : value);
      }
      return axios.post(`${API_ADDRESS}/mate/modify/mate/proc`, fd);
    },
    complete: (id, mateNo) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("mateNo", mateNo);
      return axios.post(`${API_ADDRESS}/mate/regist/mate/proc`, fd);
    },
    check: (id) => {
      const fd = new FormData();
      fd.append("userId", id);
      return axios.post(`${API_ADDRESS}/mate/regist/mate/saveCheck`, fd);
    },
    newMate: (id, itemCatgCd, mateCatg) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("itemCatgCd", itemCatgCd);
      if (mateCatg) fd.append("mateCatg", mateCatg);
      return axios.post(`${API_ADDRESS}/mate/regist/mate/newRegist`, fd);
    },
    deleteAndNewMate: (id, itemCatgCd, factoryNo, mateCatg) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("itemCatgCd", itemCatgCd);
      if (factoryNo) {
        fd.append("factoryNo", factoryNo);
      }
      if (mateCatg) fd.append("mateCatg", mateCatg);
      return axios.post(`${API_ADDRESS}/mate/regist/mate/saveDeleteAndNew`, fd);
    },
    copyMate: (id, mateNo) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("mateNo", mateNo);
      return axios.post(`${API_ADDRESS}/mate/regist/mate/styleCopy/proc`, fd);
    },
    deleteMate: (id, mateNo) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("mateNo", mateNo);
      return axios.post(`${API_ADDRESS}/mate/regist/mate/saveDelete`, fd);
    },
    // process: (depth, category1, category2) => {
    //   const fd = new FormData()
    //   fd.append('catgDepth', depth)
    //   if (category1) fd.append('catgCd1', category1)
    //   if (category2) fd.append('cateCd2', category2)
    //   return axios.post(`${API_ADDRESS}/mate/regist/mate/selectProcess`, fd)
    // },
    selectFactory: (datas) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(datas)) {
        fd.append(key, value === null ? "" : value);
      }
      return axios.post(
        `${API_ADDRESS}/mate/list/detail/facSelect/final/proc`,
        fd
      );
    },
    requestDesign: (userId, mateNo, factoryNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mateNo", mateNo);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mate/list/requestDesign/proc`, fd);
    },
    requestUnreading: (userId, mateNo, factoryNo, unreadingFlag) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mateNo", mateNo);
      fd.append("factoryNo", factoryNo);
      fd.append("unreadingFlag", unreadingFlag);
      return axios.post(
        `${API_ADDRESS}/mate/list/requestDesign/unreading/proc`,
        fd
      );
    },
    requestEstimate: (
      userId,
      mateNo,
      factoryNo,
      tagList,
      minPrice,
      maxPrice,
      description
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mateNo", mateNo);
      fd.append("factoryNo", factoryNo);
      fd.append("estimateCheck", tagList);
      fd.append("minUnitCost", minPrice);
      fd.append("maxUnitCost", maxPrice);
      fd.append("styleAmountDesc", description);
      return axios.post(`${API_ADDRESS}/mate/factory/detail/estimate/proc`, fd);
    },
    getEstimate: (userId, mateNo, factoryNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mateNo", mateNo);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mate/list/detail/facList/message`, fd);
    },
    mateListFactory: (userId, datas, pageNow, pageList) => {
      // 매칭 내역 불러오기
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      return axios.post(`${API_ADDRESS}/mate/list/factory`, fd);
    },
    mateStyleCopy: (userId, mateNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mateNo", mateNo);
      return axios.post(`${API_ADDRESS}/mate/regist/mate/LoadAndSave`, fd);
    },
    rating: (
      userId,
      mateNo,
      factoryNo,
      qualityPoint,
      pricePoint,
      communicationPoint,
      dealPlan
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mateNo", mateNo);
      fd.append("factoryNo", factoryNo);
      fd.append("qualityPoint", qualityPoint);
      fd.append("pricePoint", pricePoint);
      fd.append("communicationPoint", communicationPoint);
      fd.append("dealPlan", dealPlan);
      return axios.post(`${API_ADDRESS}/user/regist/factoryRating`, fd);
    },
    getMatchupList: (mateNo) => {
      const fd = new FormData();
      fd.append("mateNo", mateNo);
      return axios.post(`${API_ADDRESS}/mate/list/detail/matchup`, fd);
    },
  },
  designOrder: {
    regist: (
      userId,
      itemCatgCd,
      workProcess,
      catgCd,
      fabricCd,
      item,
      styleType
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("itemCatgCd", itemCatgCd);
      fd.append("workProcess", workProcess);
      fd.append("catgCd", catgCd);
      fd.append("fabricCd", fabricCd);
      fd.append("item", item);
      fd.append("styleType", styleType);
      return axios.post(`${API_ADDRESS}/designorder/regist`, fd);
    },
    registV3: (userId, itemCatgCd, workProcess, styleName, styleType, item) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("itemCatgCd", itemCatgCd);
      fd.append("workProcess", workProcess);
      fd.append("styleName", styleName);
      fd.append("styleType", styleType);
      fd.append("item", item);
      return axios.post(`${API_ADDRESS}/designorder/regist`, fd);
    },
    loadCatg: () => {
      return axios.post(`${API_ADDRESS}/designorder/load/catg`);
    },
    load: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/designorder/load`, fd);
    },
    modify: (datas) => {
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          let v = value == null ? "" : value;
          v = v == "true" || v === true ? 1 : v;
          v = v == "false" || v === false ? 0 : v;
          if (key == "sizeList") {
            v = JSON.stringify(value);
          }
          if (key == "sizeList2") {
            v = JSON.stringify(value);
          }
          if (key == "imgList") {
            fd.append(
              "fileNoList",
              value.map((e) => e.fileNo)
            );
          }
          if (key == "sizeCatgList") {
            value
              .filter((e) => e.isActive == "1")
              .forEach((e) => {
                fd.append("sizeCatgList", e.catgNo);
              });
          } else {
            fd.append(key, v);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/designorder/modify`, fd);
    },
    modifyDisplay: (userId, designOrderNo, displayFlag) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      fd.append("displayFlag", displayFlag);
      return axios.post(`${API_ADDRESS}/designorder/modify/display`, fd);
    },
    modifyRecruitStatus: (userId, designOrderNo, recruitStatus) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      fd.append("recruitStatus", recruitStatus);
      return axios.post(`${API_ADDRESS}/designorder/modify/status`, fd);
    },
    registFinal: (userId, designOrderNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      return axios.post(`${API_ADDRESS}/designorder/regist/final`, fd);
    },
    getSize: (designOrderNo) => {
      const fd = new FormData();
      fd.append("designOrderNo", designOrderNo);
      return axios.post(`${API_ADDRESS}/designorder/size`, fd);
    },
    getSizeList: (designOrderNo) => {
      const fd = new FormData();
      fd.append("designOrderNo", designOrderNo);
      return axios.post(`${API_ADDRESS}/designorder/size/catg`, fd);
    },
    getList: (userId, filterData) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (filterData) {
        for (const [key, value] of Object.entries(filterData)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/designorder/list`, fd);
    },
    getListNew: (userId, filterData) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (filterData) {
        for (const [key, value] of Object.entries(filterData)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/designorder/list/new`, fd);
    },
    getLoadList: (userId, filterData) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (filterData) {
        for (const [key, value] of Object.entries(filterData)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/designorder/load/list`, fd);
    },
    getListMain: () => {
      return axios.post(`${API_ADDRESS}/designorder/list/main`);
    },
    bookmark: (userId, designOrderNo, compType) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      fd.append("compType", compType);
      return axios.post(`${API_ADDRESS}/designorder/like`, fd);
    },
    pin: (data) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/designorder/like`, fd);
    },
    factoryPin: (userId, designOrderNo, factoryNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/designorder/like/factory`, fd);
    },
    getDetail: (userId, designOrderNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      return axios.post(`${API_ADDRESS}/designorder/detail`, fd);
    },
    part: (
      designOrderNo,
      factoryNo,
      minUnitCost,
      maxUnitCost,
      workDesc,
      unitCost
    ) => {
      const fd = new FormData();
      fd.append("designOrderNo", designOrderNo);
      fd.append("factoryNo", factoryNo);
      fd.append("minUnitCost", minUnitCost);
      fd.append("maxUnitCost", maxUnitCost);
      fd.append("workDesc", workDesc);
      fd.append("unitCost", unitCost);
      return axios.post(`${API_ADDRESS}/designorder/part`, fd);
    },
    estimate: (designOrderNo, factoryNo, finalUnitCost) => {
      const fd = new FormData();
      fd.append("designOrderNo", designOrderNo);
      fd.append("factoryNo", factoryNo);
      fd.append("finalUnitCost", finalUnitCost);
      return axios.post(`${API_ADDRESS}/designorder/part/final`, fd);
    },
    select: (designOrderNo, factoryNo) => {
      const fd = new FormData();
      fd.append("designOrderNo", designOrderNo);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/designorder/select`, fd);
    },
    designerinfo: (designOrderNo) => {
      // 요청자 정보 불러오기
      const fd = new FormData();
      fd.append("designOrderNo", designOrderNo);
      return axios.post(`${API_ADDRESS}/designorder/designerInfo`, fd);
    },
    registContract: (designOrderNo, factoryNo) => {
      const fd = new FormData();
      fd.append("designOrderNo", designOrderNo);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/designorder/regist/contract`, fd);
    },
    cancelContract: (userId, contractNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("contractNo", contractNo);
      return axios.post(`${API_ADDRESS}/designorder/cancel/contract`, fd);
    },
    registContractNew: (data, callback) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key != "contractStyleList") {
          fd.append(key, value === null ? "" : value);
        }
      }
      return axios.post(`${API_ADDRESS}/designorder/regist/contract/new`, fd);
    },
    modifyContractDesigner: (data) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(data)) {
        fd.append(key, value === null ? "" : value);
      }
      return axios.post(
        `${API_ADDRESS}/designorder/modify/contract/designer`,
        fd
      );
    },
    modifyContractFactorySign: (data) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(data)) {
        fd.append(key, value === null ? "" : value);
      }
      return axios.post(
        `${API_ADDRESS}/designorder/modify/contract/factory/sign`,
        fd
      );
    },
    getContract: (userId, designOrderNo, factoryNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/designorder/contract/info`, fd);
    },
    getContractDetail: (userId, contractNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("contractNo", contractNo);
      return axios.post(`${API_ADDRESS}/designorder/contract/detail`, fd);
    },
    getContract1: (userId, designOrderNo, contractNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      fd.append("contractNo", contractNo);
      return axios.post(`${API_ADDRESS}/designorder/contract/userInfo`, fd);
    },
    getContract2: (userId, designOrderNo, contractNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      fd.append("contractNo", contractNo);
      return axios.post(`${API_ADDRESS}/designorder/contract/orderInfo`, fd);
    },
    getContract3: (userId, designOrderNo, contractNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("designOrderNo", designOrderNo);
      fd.append("contractNo", contractNo);
      return axios.post(`${API_ADDRESS}/designorder/contract/priceInfo`, fd);
    },
    updateContract: (userId, data) => {
      const fd = new FormData();
      fd.append("userId", userId);
      for (const [key, value] of Object.entries(data)) {
        fd.append(key, value === null ? "" : value);
      }
      return axios.post(`${API_ADDRESS}/designorder/modify/contract`, fd);
    },
    updateContractStatus: (contractNo, contractStatus, factorySignFileNo) => {
      const fd = new FormData();
      fd.append("contractNo", contractNo);
      fd.append("contractStatus", contractStatus);
      if (factorySignFileNo) {
        fd.append("factorySignFileNo", factorySignFileNo);
      }
      return axios.post(`${API_ADDRESS}/designorder/modify/contractStatus`, fd);
    },
    getOrderContractList: (factoryNo, userId) => {
      const fd = new FormData();
      fd.append("factoryNo", factoryNo);
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/designorder/contract/list`, fd);
    },
  },

  mall: {
    getMaterialList: (datas) => {
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/mall/material/list`, fd);
    },
    getMaterialTabList: () => {
      return axios.post(`${API_ADDRESS}/mall/material/tab`);
    },
    getMaterialDetail: (mallNo) => {
      const fd = new FormData();
      fd.append("mallNo", mallNo);
      return axios.post(`${API_ADDRESS}/mall/material/detail`, fd);
    },
    getMaterialOption: (datas) => {
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/mall/material/option`, fd);
    },
    getMaterialOptionInit: (mallNo) => {
      const fd = new FormData();
      fd.append("mallNo", mallNo);
      return axios.post(`${API_ADDRESS}/mall/material/option/init`, fd);
    },
    getBuyList: (datas) => {
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/mall/buy/list`, fd);
    },
    getBuyDetail: (orderNum) => {
      const fd = new FormData();
      fd.append("userId", sessionStorage.getItem("userId"));
      fd.append("orderNum", orderNum);
      return axios.post(`${API_ADDRESS}/mall/buy/detail`, fd);
    },
    getBuyDetailByDtlNo: (paymentDtlNo) => {
      const fd = new FormData();
      fd.append("userId", sessionStorage.getItem("userId"));
      fd.append("paymentDtlNo", paymentDtlNo);
      return axios.post(`${API_ADDRESS}/mall/buy/detail`, fd);
    },
    buyCancel: (
      userId,
      paymentNo,
      quantity,
      refundReason,
      refundMsg,
      refundType,
      address,
      addressDetail
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("paymentNo", paymentNo);
      fd.append("quantity", JSON.stringify(quantity));
      fd.append("refundReason", refundReason);
      fd.append("refundMsg", refundMsg);
      fd.append("refundType", refundType);
      fd.append("address", address);
      fd.append("addressDetail", addressDetail);
      return axios.post(`${API_ADDRESS}/mall/buy/cancel`, fd);
    },
  },
  contract: {
    getContractList: (userId, datas, pageNow, pageList) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      return axios.post(`${API_ADDRESS}/designorder/contract/list`, fd);
    },
    getContract1: (userId, matchingNo, contractNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      fd.append("contractNo", contractNo);
      return axios.post(`${API_ADDRESS}/matching/contract/userInfo`, fd);
    },
    getContract2: (userId, matchingNo, contractNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      fd.append("contractNo", contractNo);
      return axios.post(`${API_ADDRESS}/matching/contract/orderInfo`, fd);
    },
    getContract3: (userId, matchingNo, contractNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("matchingNo", matchingNo);
      fd.append("contractNo", contractNo);
      return axios.post(`${API_ADDRESS}/matching/contract/priceInfo`, fd);
    },
    updateContract: (userId, data) => {
      const fd = new FormData();
      fd.append("userId", userId);
      for (const [key, value] of Object.entries(data)) {
        fd.append(key, value === null ? "" : value);
      }
      return axios.post(`${API_ADDRESS}/matching/modify/contract`, fd);
    },
    updateContractStatus: (contractNo, contractStatus) => {
      const fd = new FormData();
      fd.append("contractNo", contractNo);
      fd.append("contractStatus", contractStatus);
      return axios.post(`${API_ADDRESS}/matching/modify/contractStatus`, fd);
    },
  },
  account: {
    login: (id, pw) => {
      // 로그인
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("password", pw);
      fd.append("uuid", localStorage.getItem("uuid"));
      return axios.post(`${API_ADDRESS}/login/loginCheck`, fd);
    },
    loginCheckToken: (token, socialType) => {
      // 로그인
      const fd = new FormData();
      fd.append("token", token);
      fd.append("socialType", socialType);
      fd.append("uuid", localStorage.getItem("uuid"));
      return axios.post(`${API_ADDRESS}/login/loginCheck/token`, fd);
    },
    session: (id, pw) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("password", pw);
      fd.append("uuid", localStorage.getItem("uuid"));
      return axios.post(`${API_ADDRESS}/loginProc`, fd);
    },
    modifyComp: (id, compType) => {
      // 회원가입
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("compType", compType);
      return axios.post(`${API_ADDRESS}/user/modify/compType`, fd);
    },
    removeUser: (id) => {
      // 회원가입
      const fd = new FormData();
      fd.append("userId", id);
      return axios.post(`${API_ADDRESS}/user/remove`, fd);
    },
    register: (id, pw, pwConfirm, loginType, compType, marketingFlag) => {
      // 회원가입
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("password", pw);
      fd.append("passwordConfirm", pwConfirm);
      fd.append("loginType", loginType);
      fd.append("compType", compType);
      fd.append("marketingFlag", marketingFlag);
      return axios.post(`${API_ADDRESS}/user/signup/easy`, fd);
    },
    sendAuthNumber: (userName, telNum) => {
      // 아이디 찾기 인증번호 발송
      const fd = new FormData();
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      return axios.post(`${API_ADDRESS}/user/signupCertSms/Send`, fd);
    },
    dupleIdCheck: (userName, telNum) => {
      // 아이디 찾기 인증번호 확인
      const fd = new FormData();
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      return axios.post(`${API_ADDRESS}/login/duple/info`, fd);
    },
    findIdCheck: (userName, telNum, authNum) => {
      // 아이디 찾기 인증번호 확인
      const fd = new FormData();
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      fd.append("authNum", authNum);
      return axios.post(`${API_ADDRESS}/login/findUserId/proc`, fd);
    },
    findPwCheck: (userId, userName, telNum, authNum) => {
      // 아이디 찾기 인증번호 확인
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      fd.append("authNum", authNum);
      return axios.post(`${API_ADDRESS}/login/findUserPw/proc`, fd);
    },
    reqMergeUser: (telNum) => {
      const fd = new FormData();
      fd.append("telNum", telNum);
      return axios.post(`${API_ADDRESS}/login/merge/proc`, fd);
    },
    changePw: (userName, telNum, userId, password, passwordConfirm) => {
      // 아이디 찾기 인증번호 확인
      const fd = new FormData();
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      fd.append("userId", userId);
      fd.append("password", password);
      fd.append("passwordConfirm", passwordConfirm);
      return axios.post(`${API_ADDRESS}/user/modifyUserPassword/proc`, fd);
    },
    passFlagUpdate: (userId) => {
      // passFlag 변경
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/user/modifyOnlyPassFlag/proc`, fd);
    },
    pwFlagReset: (userName, telNum) => {
      const fd = new FormData();
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      return axios.post(`${API_ADDRESS}/user/modifyUserPassFlag/proc`, fd);
    },
    withdrawal: (id, reason, reasonDesc) => {
      const fd = new FormData();
      fd.append("userId", id);
      fd.append("reason", reason);
      fd.append("reasonDesc", reasonDesc);
      return axios.post(`${API_ADDRESS}/user/leaved/proc`, fd);
    },
    naverTokenUrl: (tokenUrl) => {
      return axios.get(tokenUrl);
    },
    loginEasy: (
      easyUrl,
      accessToken,
      accessTokenSha,
      compType,
      marketingFlag,
      loginType
    ) => {
      const fd = new FormData();
      fd.append("easyUrl", easyUrl);
      fd.append("accessToken", accessToken);
      fd.append("accessTokenSha", accessTokenSha);
      fd.append("compType", compType);
      fd.append("marketingFlag", marketingFlag);
      fd.append("loginType", loginType);
      return axios.post(`${API_ADDRESS}` + easyUrl, fd);
    },
    dormantToggle: (id) => {
      // 휴면해제
      const fd = new FormData();
      fd.append("userId", id);
      return axios.post(`${API_ADDRESS}/user/modify/dormantUserStatus`, fd);
    },
  },
  mypage: {
    catg: (factoryNo, depth, catgCd1, catgCd2, catgCd3) => {
      const fd = new FormData();
      fd.append("catgDepth", depth);
      if (catgCd1) fd.append("catgCd1", catgCd1);
      if (catgCd2) fd.append("catgCd2", catgCd2);
      if (catgCd3) fd.append("catgCd3", catgCd3);
      if (factoryNo) fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mypage/v3/catg`, fd);
    },
    addCatg: (userId, factoryNo, catgList) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("factoryNo", factoryNo);
      fd.append("catgJson", JSON.stringify(catgList));
      return axios.post(`${API_ADDRESS}/mypage/v3/catg/add`, fd);
    },
    removeCatg: (userId, factoryNo, catgCd) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("factoryNo", factoryNo);
      fd.append("catgCd", catgCd);
      return axios.post(`${API_ADDRESS}/mypage/v3/catg/remove`, fd);
    },
    getFactoryBoard: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/factory/dashboard`, fd);
    },
    getProfile: (key, userId) => {
      const fd = new FormData();
      fd.append(key, userId);
      return axios.post(`${API_ADDRESS}/mypage/chat/id/find`, fd);
    },
    getDesignerInfo: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/select/designerInfo`, fd);
    },
    getFactoryInfoByNo: (factoryNo) => {
      const fd = new FormData();
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mypage/select/factoryUserInfoByNo`, fd);
    },
    getFactoryInfo: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/select/factoryUserInfo`, fd);
    },
    getFactoryInfoByNoV3: (factoryNo) => {
      const fd = new FormData();
      fd.append("factoryNo", factoryNo);
      return axios.post(
        `${API_ADDRESS}/mypage/select/factoryUserInfoByNo/v3`,
        fd
      );
    },
    getFactoryInfoV3: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/select/factoryUserInfo/v3`, fd);
    },
    getUserInfo: (designerUserId, factoryUserId) => {
      const fd = new FormData();
      fd.append("designerUserId", designerUserId);
      fd.append("factoryUserId", factoryUserId);
      return axios.post(`${API_ADDRESS}/mypage/chat/id/find`, fd);
    },
    registTaxInfo: (datas) => {
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/mypage/regist/taxInfo`, fd);
    },
    factoryInfoCompleteTalk: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/factoryinfo/complete/talk`, fd);
    },
    factoryInfoStatus: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/factoryinfo/status`, fd);
    },
    signupFinTalk: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/signup/fin/talk`, fd);
    },
    getContractList: (userId, compType, datas, pageNow, pageList) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("compType", compType);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      return axios.post(`${API_ADDRESS}/mypage/contract/list`, fd);
    },
    getFactoryCategory: () => {
      return axios.post(`${API_ADDRESS}/mypage/select/factoryCatgList`);
    },
    searchTag: (keyword) => {
      const fd = new FormData();
      fd.append("commName", keyword);
      return axios.post(`${API_ADDRESS}/mypage/select/searchTagList`, fd);
    },
    searchMerit: (keyword) => {
      const fd = new FormData();
      fd.append("commCd", "51000");
      fd.append("commName", keyword);
      return axios.post(`${API_ADDRESS}/mypage/select/searchItemList`, fd);
    },
    searchItem: (keyword) => {
      const fd = new FormData();
      fd.append("commCd", "52000");
      fd.append("commName", keyword);
      return axios.post(`${API_ADDRESS}/mypage/select/searchItemList`, fd);
    },
    getChannelList: () => {
      return axios.post(`${API_ADDRESS}/mypage/select/deMajorDealerList`);
    },
    getBank: () => {
      return axios.post(`${API_ADDRESS}/mypage/select/bank`);
    },

    getFactoryAuth: (factoryNo) => {
      const fd = new FormData();
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mypage/authInfo`, fd);
    },
    getCalcAuth: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/calcInfo`, fd);
    },
    updateUserProfile: (userId, email) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("email", email);
      return axios.post(`${API_ADDRESS}/mypage/modify/user/proc`, fd);
    },
    updateBusinessInfo: (
      userId,
      licenseFlag,
      compName,
      compNum,
      address,
      addressDetail,
      areaCd,
      area,
      representName,
      compFileNo,
      bizType,
      bizClass,
      taxEmail
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (licenseFlag) fd.append("licenseFlag", licenseFlag);
      if (compName) fd.append("compName", compName);
      if (compNum) fd.append("compNum", compNum);
      if (address) fd.append("address", address);
      if (addressDetail) fd.append("addressDetail", addressDetail);
      if (areaCd) fd.append("areaCd", areaCd);
      if (area) fd.append("area", area);
      if (representName) fd.append("representName", representName);
      if (compFileNo) fd.append("compFileNo", compFileNo);
      if (bizType) fd.append("bizType", bizType);
      if (bizClass) fd.append("bizClass", bizClass);
      if (taxEmail) fd.append("taxEmail", taxEmail);
      return axios.post(`${API_ADDRESS}/mypage/regist/CompInfo/proc`, fd);
    },

    updateAuthInfo: (
      userId,
      email,
      compName,
      compNum,
      representName,
      address,
      addressDetail,
      compFileNo,
      bankFileNo,
      bankNum,
      bankUserName,
      bankName,
      bankCode,
      userName,
      telNum,
      bizType,
      bizClass,
      step,
      factoryNo
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("email", email);
      fd.append("compName", compName ? compName : "");
      fd.append("compNum", compNum ? compNum : "");
      fd.append("representName", representName ? representName : "");
      fd.append("address", address ? address : "");
      fd.append("addressDetail", addressDetail ? addressDetail : "");
      fd.append("compFileNo", compFileNo ? compFileNo : 0);
      fd.append("bankFileNo", bankFileNo ? bankFileNo : 0);
      fd.append("bankNum", bankNum ? bankNum : "");
      fd.append("bankName", bankName ? bankName : "");
      fd.append("bankUserName", bankUserName ? bankUserName : "");
      fd.append("bankCode", bankCode ? bankCode : "");
      fd.append("userName", userName ? userName : "");
      fd.append("telNum", telNum ? telNum : "");
      fd.append("bizType", bizType ? bizType : "");
      fd.append("bizClass", bizClass ? bizClass : "");
      if (step == 0) fd.append("registFlag", "1");
      if (step == 1) fd.append("calculateFlag", "1");
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mypage/modify/authInfo/proc`, fd);
    },
    registAuthInfo: (
      userId,
      email,
      compName,
      compNum,
      representName,
      address,
      addressDetail,
      compFileNo,
      factoryNo
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("email", email);
      fd.append("compName", compName ? compName : "");
      fd.append("compNum", compNum ? compNum : "");
      fd.append("representName", representName ? representName : "");
      fd.append("address", address ? address : "");
      fd.append("addressDetail", addressDetail ? addressDetail : "");
      fd.append("compFileNo", compFileNo ? compFileNo : 0);
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mypage/regist/authInfo/proc`, fd);
    },
    registCalcInfo: (
      userId,
      email,
      bankFileNo,
      bankNum,
      bankUserName,
      bankName,
      bankCode,
      userName,
      telNum,
      bizType,
      bizClass,
      factoryNo
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("email", email ? email : "");
      fd.append("bankFileNo", bankFileNo ? bankFileNo : 0);
      fd.append("bankNum", bankNum ? bankNum : "");
      fd.append("bankName", bankName ? bankName : "");
      fd.append("bankUserName", bankUserName ? bankUserName : "");
      fd.append("bankCode", bankCode ? bankCode : "");
      fd.append("userName", userName ? userName : "");
      fd.append("telNum", telNum ? telNum : "");
      fd.append("bizType", bizType ? bizType : "");
      fd.append("bizClass", bizClass ? bizClass : "");
      fd.append("factoryNo", factoryNo);
      return axios.post(`${API_ADDRESS}/mypage/regist/calcInfo/proc`, fd);
    },
    updateChannelList: (
      userId,
      clientNo,
      majorDealerList,
      siteUrls = [],
      brandNames = [],
      channelNames = [],
      profileFileNo,
      fileNoList = [],
      compDesc
    ) => {
      const fd = new FormData();
      fd.append("clientNo", clientNo);
      fd.append("majorDealerList", majorDealerList);
      fd.append("userId", userId);
      fd.append("profileFileNo", profileFileNo);

      siteUrls.forEach((e, i) => {
        fd.append("siteUrl" + (i + 1), e);
      });
      brandNames.forEach((e, i) => {
        fd.append("brandName" + (i + 1), e);
      });
      channelNames.forEach((e, i) => {
        fd.append("channelName" + (i + 1), e);
      });
      fileNoList.forEach((e) => {
        fd.append("fileNoList", e);
      });
      fd.append("compDesc", compDesc);
      return axios.post(
        `${API_ADDRESS}/mypage/regist/deMajorDealerList/proc`,
        fd
      );
    },
    updateRemainderInfo: (
      userId,
      clientName,
      // siteUrl,
      businessType,
      // joinPath,
      compDesc,
      address,
      addressDetail,
      areaCd,
      area,
      email,
      licenseFlag
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("clientName", clientName);
      // fd.append("siteUrl", siteUrl);
      fd.append("businessType", businessType);
      // fd.append("joinPath", joinPath);
      fd.append("compDesc", compDesc);
      fd.append("address", address);
      if (addressDetail) fd.append("addressDetail", addressDetail);
      fd.append("areaCd", areaCd);
      fd.append("area", area);
      if (email) {
        let v = email.split("@");
        fd.append("mailId", v[0]);
        fd.append("mailName", v[1]);
      }
      fd.append("licenseFlag", licenseFlag);
      return axios.post(`${API_ADDRESS}/mypage/regist/DeInfo/proc`, fd);
    },
    registFactoryInfo: (datas) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/mypage/regist/factory/proc`, fd);
    },
    updateFactoryInfo: (datas) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/mypage/modify/factoryInfo/proc`, fd);
    },
    updateFactoryCatgInfo: (datas) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/mypage/regist/factoryCatg/proc`, fd);
    },
    updateFactoryCatgInfoV3: (datas) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(datas)) {
        if (value) {
          fd.append(key, value);
        }
      }
      return axios.post(`${API_ADDRESS}/mypage/regist/factoryCatg/proc/v3`, fd);
    },
    getNiceData: (userId, domain) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("domain", domain);
      fd.append("authType", "M");
      return axios.post(`${API_ADDRESS}/mypage/nice/main`, fd);
    },
    niceSuccess: (userId, encodeData) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("encodeData", encodeData);
      return axios.post(`${API_ADDRESS}/mypage/nice/success`, fd);
    },
    filterList: () => {
      return axios.post(`${API_ADDRESS}/mypage/mypageLikeFilterList`);
    },
    likeFactoryList: (userId, datas, pageNow, pageList, premiumFlag) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      if (premiumFlag) fd.append("premiumFlag", premiumFlag ? "1" : "0");
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/mypage/select/likeFactoryList`, fd);
    },
    designerList: (userId, pageNow, pageList) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      return axios.post(`${API_ADDRESS}/mypage/designerList`, fd);
    },
    inquireList: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/inquire/list`, fd);
    },
    inquireDetails: (userId, inquireNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("inquireNo", inquireNo);
      return axios.post(`${API_ADDRESS}/mypage/inquire/list/detail`, fd);
    },
    insertInquire: (inquire) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(inquire)) {
        fd.append(key, value === null ? "" : value);
      }
      return axios.post(`${API_ADDRESS}/mypage/regist/inquire`, fd);
    },
    getMenuData: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/menu`, fd);
    },
    getTicketList: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/ticketList`, fd);
    },
    alarmReadAll: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/alarm/readAll`, fd);
    },

    getAlarmList: (
      userId,
      alarmStatus,
      readingFlag,
      searchText,
      pageNow,
      pageList
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      // if (alarmStatus) fd.append("alarmStatus", alarmStatus);
      // if (readingFlag) fd.append("readingFlag", readingFlag);
      // if (searchText) fd.append("searchText", searchText);
      if (pageNow) fd.append("pageNow", pageNow);
      if (pageList) fd.append("pageList", pageList);
      return axios.post(`${API_ADDRESS}/mypage/alarm/list`, fd);
    },
    getNoticeList: (
      userId,
      noticeStatus,
      readingFlag,
      searchText,
      pageNow,
      pageList
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (noticeStatus) fd.append("noticeStatus", noticeStatus);
      if (readingFlag) fd.append("readingFlag", readingFlag);
      if (searchText) fd.append("searchText", searchText);
      if (pageNow) fd.append("pageNow", pageNow);
      if (pageList) fd.append("pageList", pageList);
      return axios.post(`${API_ADDRESS}/mypage/notice/list`, fd);
    },
    getNoticeDetail: (userId, noticeNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("noticeNo", noticeNo);
      return axios.post(`${API_ADDRESS}/mypage/notice/list/detail`, fd);
    },
    postNoticeRead: (userId, noticeNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("noticeNo", noticeNo);
      return axios.post(`${API_ADDRESS}/mypage/notice/list/readingCheck`, fd);
    },
    noticeCheck: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/notice/check`, fd);
    },
    postAlarmRead: (userId, alarmNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("alarmNo", alarmNo);
      return axios.post(`${API_ADDRESS}/mypage/alarm/list/readingCheck`, fd);
    },
    alarmCheck: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/mypage/alarm/check`, fd);
    },
  },
  company: {
    getBizData: (searchText) => {
      const fd = new FormData();
      fd.append("searchText", searchText);
      return axios.post(`${API_ADDRESS}/comp/api/compSearch/proc`, fd);
    },
  },
  payment: {
    paymentRe: (datas) => {
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/repayment`, fd);
    },
    paymentCancel: (datas) => {
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/cancel`, fd);
    },
    issuanceTax: (datas) => {
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/issue/tax`, fd);
    },
    certTax: (datas) => {
      const fd = new FormData();
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/cert/tax`, fd);
    },
    getCalcDetail: (userId, paymentDtlNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("paymentDtlNo", paymentDtlNo);
      return axios.post(`${API_ADDRESS}/payment/calc/detail`, fd);
    },
    getCalcList: (userId, datas, pageNow, pageList) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (pageNow) fd.append("pageNow", pageNow); //현재 페이지
      if (pageList) fd.append("pageList", pageList); //리스트 수
      return axios.post(`${API_ADDRESS}/payment/calc/list`, fd);
    },
    getManageList: (userId, datas) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/manage/list`, fd);
    },
    getPaymentList: (userId, datas) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/list`, fd);
    },
    getPaymentDetail: (userId, paymentNo) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("paymentNo", paymentNo);
      return axios.post(`${API_ADDRESS}/payment/detail`, fd);
    },
    createPayple: (userId, itemId, paymentType) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("itemId", itemId);
      fd.append("paymentType", paymentType);
      return axios.post(
        `${API_ADDRESS}/payment/create/payple/` + paymentType,
        fd
      );
    },
    ticketList: (userId, datas) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (datas) {
        for (const [key, value] of Object.entries(datas)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/ticketList`, fd);
    },
    getUsedticket: (userId) => {
      const fd = new FormData();
      fd.append("userId", userId);
      return axios.post(`${API_ADDRESS}/payment/usedTicket`, fd);
    },
    paymentList: (userId, status, orderType) => {
      const fd = new FormData();
      fd.append("userId", userId);
      if (status) fd.append("status", status);
      if (orderType) fd.append("orderType", orderType);
      return axios.post(`${API_ADDRESS}/payment/ticketList`, fd);
    },
    createToss: (
      userId,
      factoryNo,
      itemName,
      paymentAmount,
      paymentType,
      paymentDtlNo,
      paytype,
      calculateType,
      receiptType,
      identityNum,
      designerInfo
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("factoryNo", factoryNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      fd.append("paymentDtlNo", paymentDtlNo);
      fd.append("paytype", paytype);
      if (calculateType) fd.append("calculateType", calculateType);
      else fd.append("calculateType", "0");
      if (receiptType) fd.append("receiptType", receiptType);
      else fd.append("receiptType", "0");
      fd.append("identityNum", identityNum);
      if (designerInfo) {
        for (const [key, value] of Object.entries(designerInfo)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/toss/create`, fd);
    },
    createPaypleFactory: (
      userId,
      factoryNo,
      itemName,
      paymentAmount,
      paymentType,
      paymentDtlNo,
      paytype,
      calculateType,
      receiptType,
      identityNum,
      designerInfo
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("factoryNo", factoryNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      fd.append("paymentDtlNo", paymentDtlNo);
      fd.append("paytype", paytype);
      if (calculateType) fd.append("calculateType", calculateType);
      else fd.append("calculateType", "0");
      if (receiptType) fd.append("receiptType", receiptType);
      else fd.append("receiptType", "0");
      fd.append("identityNum", identityNum);
      if (designerInfo) {
        for (const [key, value] of Object.entries(designerInfo)) {
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/payple/create/factory`, fd);
    },
    createTossBilling: (userId, itemId, paymentType) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("itemId", itemId);
      fd.append("paymentType", paymentType);
      return axios.post(`${API_ADDRESS}/payment/toss/create/billing`, fd);
    },
    createTossDesignOrder: (userId, paymentNo, paymentDtlNo, paymentType) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("contractNo", paymentNo);
      fd.append("contractNo", paymentDtlNo);
      fd.append("paymentType", paymentType);
      return axios.post(`${API_ADDRESS}/payment/toss/create/matching`, fd);
    },
    createTossMatching: (userId, contractNo, paymentType) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("contractNo", contractNo);
      fd.append("paymentType", paymentType);
      return axios.post(`${API_ADDRESS}/payment/toss/create/matching`, fd);
    },
    createTossMall: (
      userId,
      mallNo,
      itemName,
      paymentAmount,
      paymentType,
      itemDetail,
      userName,
      telNum,
      address,
      addressDetail,
      deliveryMemo,
      itemDetailInfo
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mallNo", mallNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      fd.append("itemDetail", itemDetail);
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      fd.append("address", address);
      fd.append("addressDetail", addressDetail);
      if (deliveryMemo) fd.append("deliveryMemo", deliveryMemo);
      fd.append("itemDetailInfo", JSON.stringify(itemDetailInfo));

      return axios.post(`${API_ADDRESS}/payment/toss/create/mall`, fd);
    },
    createTossMallBasket: (
      userId,
      mallNo,
      itemName,
      paymentAmount,
      paymentType,
      itemDetail,
      userName,
      telNum,
      address,
      addressDetail,
      deliveryMemo
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mallNo", mallNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      fd.append("itemDetail", JSON.stringify(itemDetail));
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      fd.append("address", address);
      fd.append("addressDetail", addressDetail);
      if (deliveryMemo) fd.append("deliveryMemo", deliveryMemo);

      return axios.post(`${API_ADDRESS}/payment/toss/create/mall/basket`, fd);
    },
    createPaypleMallBasket: (
      userId,
      mallNo,
      itemName,
      paymentAmount,
      paymentType,
      itemDetail,
      userName,
      telNum,
      address,
      addressDetail,
      deliveryMemo
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mallNo", mallNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      fd.append("itemDetail", JSON.stringify(itemDetail));
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      fd.append("address", address);
      fd.append("addressDetail", addressDetail);
      if (deliveryMemo) fd.append("deliveryMemo", deliveryMemo);

      return axios.post(`${API_ADDRESS}/payment/payple/create/mall/basket`, fd);
    },
    createTossMallBasketDirect: (
      userId,
      mallNo,
      itemName,
      paymentAmount,
      paymentType,
      itemDetail,
      userName,
      telNum,
      address,
      addressDetail,
      deliveryMemo,
      direct
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mallNo", mallNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      fd.append("itemDetail", JSON.stringify(itemDetail));
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      fd.append("address", address);
      fd.append("addressDetail", addressDetail);
      if (deliveryMemo) fd.append("deliveryMemo", deliveryMemo);
      if (direct) {
        for (const [key, value] of Object.entries(direct)) {
          console.log(key, value);
          if (value) {
            fd.append(key, value);
          }
        }
      }

      return axios.post(
        `${API_ADDRESS}/payment/toss/create/mall/basket/direct`,
        fd
      );
    },
    createTossMallDirect: (
      userId,
      mallNo,
      itemName,
      paymentAmount,
      paymentType,
      itemDetail,
      userName,
      telNum,
      address,
      addressDetail,
      deliveryMemo,
      itemDetailInfo,
      direct
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("mallNo", mallNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      fd.append("itemDetail", itemDetail);
      fd.append("userName", userName);
      fd.append("telNum", telNum);
      fd.append("address", address);
      fd.append("addressDetail", addressDetail);
      console.log(direct);
      if (direct) {
        for (const [key, value] of Object.entries(direct)) {
          console.log(key, value);
          if (value) {
            fd.append(key, value);
          }
        }
      }
      if (deliveryMemo) fd.append("deliveryMemo", deliveryMemo);
      fd.append("itemDetailInfo", JSON.stringify(itemDetailInfo));

      return axios.post(`${API_ADDRESS}/payment/toss/create/mall/direct`, fd);
    },

    createTossCustom: (
      userId,
      orderNo,
      itemName,
      paymentAmount,
      paymentType
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("orderNo", orderNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      return axios.post(`${API_ADDRESS}/payment/toss/create/custom`, fd);
    },
    createPaypleCustom: (
      userId,
      orderNo,
      itemName,
      paymentAmount,
      paymentType
    ) => {
      const fd = new FormData();
      fd.append("userId", userId);
      fd.append("orderNo", orderNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      return axios.post(`${API_ADDRESS}/payment/payple/create/custom`, fd);
    },
    createTossCustomDirect: (
      orderNo,
      itemName,
      paymentAmount,
      paymentType,
      userInfo
    ) => {
      const fd = new FormData();
      fd.append("orderNo", orderNo);
      fd.append("itemName", itemName);
      fd.append("paymentAmount", paymentAmount);
      fd.append("paymentType", paymentType);
      console.log(userInfo);
      if (userInfo) {
        for (const [key, value] of Object.entries(userInfo)) {
          console.log(key, value);
          if (value) {
            fd.append(key, value);
          }
        }
      }
      return axios.post(`${API_ADDRESS}/payment/toss/create/custom/direct`, fd);
    },
  },
};
